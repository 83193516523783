import React from 'react'
import './share.module.scss'
import { Modal, Drawer, Collapse } from 'antd'
import {Desktop, Mobile } from '../../utils/responsive'
import illustaition from '../../../assets/illustrations/finish.svg'
import { apolloClient } from '../../utils/graphql'
import { gql } from "@apollo/client";
import Link from 'next/link'
import { WhatsAppOutlined, CopyOutlined } from "@ant-design/icons";
import displayNotification from '../ui_components/notification'
import shareLink from '../../utils/shareLink'
const { Panel } = Collapse

const ShareProduct = ({ t, id, closeModal, type, noOpportunity, saveOnShare, uid, role }) => {

  const doShare = (shareOn) => {
    let link = shareLink(shareOn, id, type, uid, role)
    let { data } = apolloClient.mutate({
      mutation: SAVE_SHARED_LINK,
      variables: {
        uid,
        link,
        action: 'share'
      }
    })
    if (saveOnShare) {
      saveOnShare()
    }
    closeModal()
    if (shareOn === 'link') {
      displayNotification(<p>{t('Link copied to clipboard')}</p>)
    }
  }


  const renderBody = () => {
    if (type === 'product' && !noOpportunity) {
      return (
        <div className="share">
          <h5>{t('Share product')}</h5>
          <Link href={`/opportunities/new?withProduct=${id}`}>
            <div className="create-opportunity">
              <div className="text">
                <h5>{t('Create an opportunity before sharing')}</h5>
                <p>{t('Invest 1 extra minute! Master your sales strategy and obtain clearer insights on your company’s performance every week.')}</p>
                <p className="link">{t("Learn how that's possible!")}</p>
              </div>
              <div className="image">
                <img src={illustaition} />
              </div>
            </div>
          </Link>
          <Collapse ghost expandIconPosition="right">
            <Panel key="1" header={
              <div>
                <p>{t('I just want to share immediately')}</p>
                <p className="small">{t('...and miss out on advice to better my revenue')}</p>
              </div>
            }
            >
              <div onClick={() => doShare('whatsapp')} className="whatsapp"><WhatsAppOutlined />{t('Share via WhatsApp')}</div>
              <div onClick={() => doShare('link')} className="link"><CopyOutlined />{t('Copy link to share')}</div>
            </Panel>
          </Collapse>
        </div>
      )
    } else {
      return (
        <div className="share">
          <div className="share-btns">
          <div onClick={() => doShare('whatsapp')} className="whatsapp"><WhatsAppOutlined />{t('Share via WhatsApp')}</div>
          <div onClick={() => doShare('link')} className="link"><CopyOutlined />{t('Copy link to share')}</div>
          {saveOnShare
            ? <div 
                onClick={() => {
                  closeModal()
                  saveOnShare()
                }} 
                className="only-save">
                {t('I no longer want to share, just save it.')}
              </div>
            : <div onClick={closeModal} className="cancel">{t('Cancel')}</div>
          }
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <Desktop>
        <Modal
          visible={id}
          title={null}
          footer={null}
          width={408}
          onCancel={closeModal}
          wrapClassName={`share-modal`}
        >
          {renderBody()}
        </Modal>
      </Desktop>

      <Mobile>
        <Drawer
          title={null}
          placement="bottom"
          height={'auto'}
          onClose={closeModal}
          className={`share-modal`}
          visible={id}
        >
          {renderBody()}
        </Drawer>
      </Mobile>
    </div>
  )
}

export default ShareProduct;

const SAVE_SHARED_LINK = gql`
  mutation saveSharedLink($uid: ID, $link: String, $action: String) {
    saveSharedLink(link: $link, action: $action, uid: $uid)
  }
`