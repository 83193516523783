import React from 'react'
import './notification.module.scss'
import { notification } from 'antd';
import img from '../../../assets/illustrations/success.svg';
import img_attention from '../../../assets/illustrations/attention.svg'

const displayNotification = (text, placement, isWarning) => {
  notification.open({
    placement: placement || 'bottomLeft',
    duration: 5,
    className: 'notification',
    icon: <img src={isWarning ? img_attention : img} />,
    description: text
  })
}

export default displayNotification;