import { environment } from '../utils/graphql'

const shareLink = (shareOn, id, type, agent, role) => {

  const url = environment === 'PRODUCTION'
    ? 'https://crm.perpetual.com.my'
    : 'http://perpetual.lizard.global'

  let link = type === 'opportunity'
    ? `${url}/cost-breakdown/${id}?${role && role === 'reseller' ? 'reseller' : 'agent'}=${agent}`
    : `${url}/cost-breakdown/${id}?type=product&${role && role === 'reseller' ? 'reseller' : 'agent'}=${agent}`
    
  if (shareOn === 'whatsapp') {
    window.open(`https://wa.me/?text=${encodeURIComponent(link)}`, '_blank')
  } else if (shareOn === 'link') {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  return link
}

export default shareLink